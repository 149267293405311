export default {
  namespaced: true,
  state: {
    content: "",
    color: "",
    timeout: 0
  },
  mutations: {
    showMessage(state, payload) {
      state.content = payload.content;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
  },
  getters: {},
  actions: {},
};
