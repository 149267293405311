<template>
  <div class="main">
    <div class="main__container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.$router.replace("/login");
        } else if (this.$route.path.startsWith("/login")) {
          this.$router.replace("/home");
        }
      },
    },
    accountsFetchError(val) {
      if (val) {
        this.$router.replace({ name: "errorPage", params: { message: val } });
      }
    },
  },
  methods: {
    ...mapActions({
      initCustomer: "init",
      initRealm: "realm/init",
    }),
  },
  mounted() {
    this.initCustomer();
    this.initRealm();
  },
};
</script>

<style lang="scss">
.main {
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.08);
}

.app-loader {
  display: flex;
  padding: 20vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;

  &__message {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
}
</style>
