import Vue from "vue";
import Vuex from "vuex";

import snackbar from "./snackbar";
import user from "./user";
import realm from "./realm";
import business from "./business";
import subscription from "./subscription"
import userService from "../services/user"
import partner from "./partner/partner"
import partnerBusiness from "./partner/business"

Vue.use(Vuex);

function isEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function isPhoneNumber(value) {
  const regEx = /^\+[1-9]\d{10,14}$/;

  return regEx.test(value);
}

function isUserId(value) {
  return (!isEmail(value) && !isPhoneNumber(value) && value.length >= 21)
}

const store = new Vuex.Store({
  state: {
    customerId: null,
    customerPhone: null,
    customerEmail: null,
    customerName: null,
    customerWaiting: false,
    customerError: null
  },
  getters: {
    customerId: (state) => state.customerId,
    customerName: (state) => state.customerName,
    customerEmail: (state) => state.customerEmail,
    customerPhone: (state) => state.customerPhone,
    customerWaiting: (state) => state.customerWaiting,
    customerError: (state) => state.customerError,
  },
  mutations: {
    setCustomer(state, customer) {
      if (customer) {
        state.customerId = customer.customer.oId
        state.customerName = customer.customer.name
        state.customerEmail = customer.customer.email
        state.customerPhone = customer.customer.phone
        localStorage.setItem("lair_active_customer", JSON.stringify(customer))
      } else {
        state.customerId = null
        state.customerName = null
        state.customerEmail = null
        state.customerPhone = null
        localStorage.removeItem("lair_active_customer")
      }
      state.customerWaiting = false
      state.customerError = null
    },
    setCustomerWaiting(state, isWaiting) {
      state.customerWaiting = isWaiting
      state.customerError = null
    },
    setCustomerError(state, errorMessage) {
      state.customerWaiting = false
      state.customerError = errorMessage
    }
  },
  actions: {
    init({ commit }) {
      const activeCustomer = JSON.parse(localStorage.getItem("lair_active_customer"))
      if (activeCustomer && activeCustomer.timestamp < Date.now() - 3600 * 1000) {
        localStorage.removeItem("lair_active_customer")
      } else {
        commit("setCustomer", activeCustomer)
      }
    },
    async fetchCustomerDetails({ commit, rootGetters }, identifier) {
      commit("setCustomerWaiting", true);
      try {
        const realmId = rootGetters["realm/active"].id
        let user = null
        if (isEmail(identifier)) {
          user = await userService.getUserByEmail(realmId, identifier)
        } else if (isPhoneNumber(identifier)) {
          user = await userService.getUserByPhoneNumber(realmId, identifier)
        } else if (isUserId(identifier)) {
          user = await userService.getUserById(realmId, identifier)
        }
        commit("setCustomer", {customer: user.user, timestamp: Date.now()})
      } catch (e) {
        commit("setCustomerError", e.message || "Could not fetch customer details.");
      }
    },
    clearCustomer({ commit }) {
      commit("setCustomer", null)
    }
  },
  modules: {
    snackbar,
    user,
    realm,
    business,
    subscription,
    partner,
    partnerBusiness,
  },
});

export default store;
