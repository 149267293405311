import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerAuthCallback } from "./firebase";
import vuetify from "./plugins/vuetify";
import notifier from "./plugins/notifier";
import './registerServiceWorker'

Vue.config.productionTip = false;

let isVueInitialized = false;
Vue.use(notifier, { store });

registerAuthCallback(async (user) => {
  store.dispatch("user/setUser", user);
  if (!isVueInitialized) {
    new Vue({
      render: (h) => h(App),
      store,
      vuetify,
      router,
    }).$mount("#app");
    isVueInitialized = true;
  }
});
