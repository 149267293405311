import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import * as firebaseui from "firebaseui";
import config from "../config/config";
import { getPerformance } from "firebase/performance";
import axios from "axios";

const app = initializeApp(config.firebaseConfig);
if (config.environment === "production") getAnalytics();
//firestore.enablePersistence({ synchronizeTabs: true });
export const firebaseUI = new firebaseui.auth.AuthUI(getAuth());

getPerformance();

export const registerAuthCallback = (callback) => {
  getAuth(app).onAuthStateChanged(async function (user) {
    const appUser = user
      ? { id: user.uid, name: user.displayName, email: user.email }
      : null;
    await callback(appUser);
  });

  axios.interceptors.request.use(
    async (config) => {
      const user = getAuth().currentUser;
      if (user) {
        try {
          let token = await user.getIdToken();
          config.headers["Authorization"] = `Bearer ${token}`;
        } catch (error) {
          console.error(error);
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export const auth = getAuth(app);

export default app;
