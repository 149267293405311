import axios from "axios";
import { getApiUrl } from "../realms";

// Send premium subscribe link
const createPremiumLink = async (realmId, emailAddress, businessId, offerCode, isOfferYearlyOnly, appId, medium) => {
  try {
    const response = await axios.post(
      `${getApiUrl()}/realm/${realmId}/business/${businessId}/premiumLink`,
      {
        emailAddress,
        offerCode,
        isOfferYearlyOnly,
        appId,
        medium
      }
    );
    return response.data;
  } catch (e) {
    console.error(e)
    throw new Error(e.response.data || "Unknown error")
  }
};

// Create link for Razorpay
const createRazorpayLink = async (realmId, emailAddress, businessId, offerCode, appId) => {
  try {
    const response = await axios.post(
      `${getApiUrl()}/realm/${realmId}/business/${businessId}/razorpayOneTimeLink`,
      {
        emailAddress,
        offerCode,
        appId
      }
    );
    return response.data;
  } catch (e) {
    console.error(e)
    throw new Error(e.response.data || "Unknown error")
  }
};

// Create link for Razorpay
const createFreeTrialExtension = async (realmId, businessId, emailAddress, freeTrialDays, appId) => {
  try {
    await axios.post(
      `${getApiUrl()}/realm/${realmId}/business/${businessId}/freeTrialExtension`,
      {
        emailAddress,
        freeTrialDays,
        appId
      }
    );
  } catch (e) {
    console.error(e)
    throw new Error(e.response.data || "Unknown status")
  }
};

const createManualSubscription = async (realmId, emailAddress, businessId, startDate, period, notes, appId) => {
  try {
    await axios.post(
      `${getApiUrl()}/realm/${realmId}/business/${businessId}/subscription`,
      {
        type: "manual",
        emailAddress,
        notes,
        period,
        startDate,
        appId
      }
    );
  } catch (e) {
    console.error(e)
    throw new Error(e.response.data || "Unknown status")
  }
};

// Create link for Razorpay
const updateBusinessBlockStatus = async (realmId, businessId, isBlock) => {
  await axios.post(
    `${getApiUrl()}/realm/${realmId}/business/${businessId}/block`,
    {
      isBlock
    }
  );
  return true
};


// Business level

// Create premium link
// Create razorpay link
// Block business
// Add manual subscription


const getBusinessDetailsFromEmail = async (realmId, email) => {
  const response = await axios.get(
    `${getApiUrl()}/realm/${realmId}/business/getBusinessesByEmail?email=${email}`
  );
  return response.data || [];
}

const getBusinessDetails = async (realmId, businessId) => {
  const response = await axios.get(
    `${getApiUrl()}/realm/${realmId}/business/` + businessId
  );
  return response.data || [];
}

const changeStoreName = async ({ realmId, businessId, oldName, newName }) => {
  const response = await axios.post(`${getApiUrl()}/realm/${realmId}/business/${businessId}/changeStoreName`, {
    oldName,
    newName
  })

  if (response.status == 200) {
    return true;
  }
  return false
}

const changeOwner = async (realmId, businessId, oldEmail, newEmail) => {
  try {
    const response = await axios.post(`${getApiUrl()}/realm/${realmId}/business/${businessId}/changeOwnerEmail`, {
      oldEmail,
      newEmail
    })

    return response.status == 200
  } catch (e) {
    if (e?.response?.data?.errorMessage) throw new Error(e?.response?.data?.errorMessage)
    throw new Error(e.message || "Unknown Error occurred")
  }
}

const getBusinessNameFromId = async (realmId, businessId) => {
  const response = await axios.get(
    `${getApiUrl()}/realm/${realmId}/business/` + businessId
  );
  return (response.data || {}).name;
}

export default {
  getBusinessDetailsFromEmail,
  getBusinessDetails,
  changeStoreName,
  createManualSubscription,
  createPremiumLink,
  createRazorpayLink,
  createFreeTrialExtension,
  updateBusinessBlockStatus,
  getBusinessNameFromId,
  changeOwner
};
