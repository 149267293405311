import partnerService from "../../services/partner";

export default {
  namespaced: true,
  state: {
    isWlAccountFetchWaiting: true,
    isResellerAccountFetchWaiting: true,
    wlAccountList: [],
    resellerAccountList: [],
    activeAccountId: null,
    activeAccountType: null,
    fetchError: null,
    isFetchWaiting: true
  },
  getters: {
    isFetchWaiting: (state) => state.isWlAccountFetchWaiting && state.isResellerAccountFetchWaiting,
    wlAccountList: (state) => state.wlAccountList,
    resellerAccountList: state => state.resellerAccountList,
    fetchError: (state) => state.fetchError,
    active: (state) => {
      if (state.activeAccountType && state.activeAccountId) {
        if (state.activeAccountType == "reseller") {
          let accounts = state.resellerAccountList;
          let activeAccount = accounts[0];
          const selectedAccount = accounts.find((x) => x.id === state.activeAccountId);
          const account = selectedAccount || activeAccount;
          account.type = "reseller"
          return account;
        } else if (state.activeAccountType == "whitelabel") {
          let accounts = state.wlAccountList;
          let activeAccount = accounts[0];
          const selectedAccount = accounts.find((x) => x.id === state.activeAccountId);
          const account = selectedAccount || activeAccount;
          account.type = "whitelabel"
          return account;
        }
      }
      return null
    },
    activeAccountType: state => state.activeAccountType
  },
  mutations: {
    setWlAccountList(state, accounts) {
      state.isWlAccountFetchWaiting = false;
      state.wlAccountList = accounts;
    },
    setResellerAccountList(state, accounts) {
      state.isResellerAccountFetchWaiting = false;
      state.resellerAccountList = accounts;
    },
    setFetchError(state, message) {
      state.isFetchWaiting = false;
      state.fetchError = message;
    },
    setActiveAccount(state, { id, type }) {
      state.activeAccountId = id;
      state.activeAccountType = type

    },
    clearAll(state) {
      state.isFetchWaiting = true;
      state.list = [];
      state.active = null;
      state.fetchError = null;
    },
  },
  actions: {
    async fetchAccounts({ commit, dispatch }, user) {
      try {
        if (user == null) {
          throw new Error("User is not logged in.");
        }

        const accountsData = await partnerService.getAccounts();
        const resellerAccounts = accountsData.data.resellerAccounts;
        const wlAccounts = accountsData.data.wlAccounts;

        if ((!wlAccounts || !wlAccounts.length) && (!resellerAccounts || !resellerAccounts.length)) {
          commit("setFetchError", "No accounts linked.");
          return;
        }

        commit("setResellerAccountList", resellerAccounts || [])
        commit("setWlAccountList", wlAccounts || [])

        let activeAccountId = localStorage.getItem("active_account_id")
        if (activeAccountId) {
          if (wlAccounts && wlAccounts.length) {
            let account = wlAccounts.find(x => x.id == activeAccountId)
            if (account != null) {
              dispatch("setActiveAccount", { accountType: "whitelabel", accountId: account.id });
              return;
            }
          }
          if (resellerAccounts && resellerAccounts.length) {
            let account = resellerAccounts.find(x => x.id == activeAccountId)
            if (account != null) {
              dispatch("setActiveAccount", { accountType: "reseller", accountId: account.id })
              return;
            }
          }
        }

        if (wlAccounts && wlAccounts.length) dispatch("setActiveAccount", { accountType: "whitelabel", accountId: wlAccounts[0].id });
        else if (resellerAccounts && resellerAccounts.length) dispatch("setActiveAccount", { accountType: "reseller", accountId: resellerAccounts[0].id })

      } catch (e) {
        console.log(e);
        commit(
          "setFetchError",
          "An error occurred while connecting to the server. Please try again after sometime."
        );
      }
    },
    setActiveAccount({ commit }, { accountId, accountType }) {
      commit("setActiveAccount", { id: accountId, type: accountType });
      localStorage.setItem("active_account_id", accountId)
    },
    clearAccounts({ commit }) {
      commit("clearAll");
    },
    async fetchAccountData({ dispatch }, account) {
      await dispatch("partnerBusiness/fetchBusinesses", { activeAccount: account, bId: null, options:null }, { root: true });
    },
    clearAccountData({ dispatch }) {
      dispatch("partnerBusiness/clearAllBusinesses", null, { root: true });
    },
  },
};
