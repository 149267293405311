// Manage the Environment Variables properly when deploying to other portals

let config;

if (process.env.NODE_ENV === "development") {
  config = {
    environment: "development",
    apiUrl: "http://127.0.0.1:5001/zobaze-lair/us-central1/lairAPI/api/v1",
    debugMode: false,
    firebaseConfig: {
      apiKey: "AIzaSyA8Hdwy8N4pAMgmrky7AT3dOxjQtPf9M4E",
      authDomain: "zobaze-lair.firebaseapp.com",
      databaseURL: "https://zobaze-lair.firebaseio.com",
      projectId: "zobaze-lair",
      storageBucket: "zobaze-lair.appspot.com",
      messagingSenderId: "490516661579",
      appId: "1:490516661579:web:e8d8b25818518563b894ce",
      measurementId: "G-8DJ4YDXFYK",
    },
  };
} else if (process.env.NODE_ENV) {
  config = {
    environment: "production",
    apiUrl: "https://lair-api.zobaze.com/api/v1",
    debugMode: false,
    firebaseConfig: {
      apiKey: "AIzaSyA8Hdwy8N4pAMgmrky7AT3dOxjQtPf9M4E",
      authDomain: "zobaze-lair.firebaseapp.com",
      databaseURL: "https://zobaze-lair.firebaseio.com",
      projectId: "zobaze-lair",
      storageBucket: "zobaze-lair.appspot.com",
      messagingSenderId: "490516661579",
      appId: "1:490516661579:web:e8d8b25818518563b894ce",
      measurementId: "G-8DJ4YDXFYK",
    },
  };
}

export default config;
