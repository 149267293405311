import { sortBy } from "lodash";
import subscriptionService from "../services/subscription";

export default {
  namespaced: true,
  state: {
    isFetchWaiting: true,
    list: [],
    fetchError: null,
  },
  getters: {
    isFetchWaiting: (state) => state.isFetchWaiting,
    list: (state) => state.list,
    fetchError: (state) => state.fetchError,
  },
  mutations: {
    setList(state, subscriptions) {
      state.isFetchWaiting = false;
      state.fetchError = null
      state.list = subscriptions;
    },
    setFetchError(state, message) {
      state.isFetchWaiting = false;
      state.fetchError = message;
    },
  },
  actions: {
    async fetchSubscriptions({ state, commit }, { realmId, userId }) {
      state.isFetchWaiting = true;
      try {
        if (!realmId) throw new Error("Bad State");
        let subscriptions = [];
        subscriptions = await subscriptionService.getUserSubscriptions(realmId, userId);
        commit("setList", sortBy(subscriptions, "startTime", "desc").reverse())
      } catch (error) {
        console.error(error);
        commit("setFetchError", error.message);
      }
    },
    async clearSubscriptions({ commit, state }) {
      commit("setList", []);
      commit("setFetchError", null);
      state.isFetchWaiting = true;
    },
  },
};
