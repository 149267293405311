import axios from "axios";
import { getApiUrl } from "../../realms";

const getWhitelabelBusinesses = async ({ wlId, bId, options }) => {
  try {
    const response = await axios.get(
      `${getApiUrl()}/partner/lair/wl/${wlId}/businesses/`,
      {
        params: {
          bId,
          options
        }
      }
    );
    return response.data.businesses || [];
  } catch (e) {
    console.error(e);
    throw new Error(e.response.data || "server error");
  }
};

export default {
  getWhitelabelBusinesses,
};
