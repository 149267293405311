import axios from "axios";
import { getApiUrl } from "../realms";

const getAccounts = async () => {
  try {
    const response = await axios.get(
      `${getApiUrl()}/partner/lair/accounts`
    );
    return response;
  } catch (e) {
    console.error(e);
    throw new Error(e.response.data || "server error");
  }
};


const addTokensToReseller = async ({ resellerId, tokens, note }) => {
  try {
    const response = await axios.post(
      `${getApiUrl()}/partner/lair/reseller/${resellerId}/addTokens`,
      { tokens, note }
    );
    return response;
  } catch (e) {
    console.error(e);
    throw new Error(e.response.data || "server error");
  }
};

export default {
  getAccounts,
  addTokensToReseller,
};
