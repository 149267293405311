import axios from "axios";
import { getApiUrl } from "../realms";

const getUserSubscriptions = async (realmId, userId) => {
    const response = await axios.get(
        `${getApiUrl()}/realm/${realmId}/user/${userId}/subscriptions`
    );
    return response.data || [];
}

export default {
    getUserSubscriptions
}