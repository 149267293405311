import { auth } from "../firebase";
import { signOut } from "firebase/auth";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user: (state) => state.user,
    isLoggedIn: (state) => !!state.user,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async setUser({ commit, dispatch }, user) {
      if (user) {
        commit("setUser", user);
        await dispatch("partner/fetchAccounts", user, { root: true });
      } else {
        commit("setUser", null);
      }
    },
    async logout({ commit, dispatch }) {
      signOut(auth);
      commit("setUser", null);
      dispatch("partner/clearAccounts", null, { root: true });
    }
  },
};
