import businessSubscriptionService from "../services/subscription/business";

export default {
  namespaced: true,
  state: {
    email: null,
    isFetchWaiting: true,
    list: [],
    fetchError: null,
  },
  getters: {
    email: (state) => state.email,
    isFetchWaiting: (state) => state.isFetchWaiting,
    list: (state) => state.list,
    fetchError: (state) => state.fetchError,
  },
  mutations: {
    setList(state, businesses) {
      state.isFetchWaiting = false;
      state.fetchError = null
      state.list = businesses;
    },
    setFetchError(state, message) {
      state.isFetchWaiting = false;
      state.fetchError = message;
    },
  },
  actions: {
    async fetchBusinesses({ state, commit }, { realmId, email }) {
      state.isFetchWaiting = true;
      try {
        if (!realmId) throw new Error("Bad State");
        state.email = email
        let businesses = [];
        businesses = await businessSubscriptionService.getBusinessDetailsFromEmail(realmId, email);
        commit("setList", businesses);
      } catch (error) {
        console.error(error);
        commit("setFetchError", error.message);
      }
    },
    getBusinessFromList({ state }, businessId) {
      if (!state.list || !state.list.length) return null;
      for (let business of state.list) {
        if (business.id == businessId) {
          return business;
        }
      }
      return null
    },
    updateBusinessOnRefetch({ state, dispatch, commit }, business) {
      const staleBusiness = dispatch('getBusinessFromList', business.id)
      if (staleBusiness) {
        commit("setList", state.list.map(x => x.id == business.id ? business : x))
      }
    },
    // async fetchBusiness({ state, commit }, { realmId, businessId }) {
    //   try {
    //     if (!realmId) throw new Error("Bad State");
    //     let businessDetails = await businessSubscriptionService.getBusinessDetails(realmId, businessId);
    //     return businessDetails
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async clearBusinesses({ commit, state }) {
      commit("setList", []);
      commit("setFetchError", null);
      state.isFetchWaiting = true;
    },
    // async updateToPremium({ rootGetters }, { accountId, business, discountCode, period }) {
    //   if (rootGetters["partner/activeAccountType"] == "whitelabel") {
    //     await whitelabelBusinessService.updateBusinessToPremium(
    //       accountId,
    //       business,
    //       period,
    //       discountCode
    //     );
    //   } else {
    //     await partnerBusinessService.updateBusinessToPremium(
    //       accountId,
    //       business,
    //       period
    //     );
    //   }
    // },
    // async blockBusiness({ rootGetters }, { accountId, business }) {
    //   if (rootGetters["partner/activeAccountType"] == "whitelabel") {
    //     await whitelabelBusinessService.blockBusiness(accountId, business.id);
    //   } else {
    //     throw new Error("Action not permitted")
    //   }
    // },
    // async unblockBusiness({ rootGetters }, { accountId, business }) {
    //   if (rootGetters["partner/activeAccountType"] == "whitelabel") {
    //     await whitelabelBusinessService.unblockBusiness(accountId, business.id);
    //   } else {
    //     throw new Error("Action not permitted")
    //   }
    // },
    // async subscribeViaResellerRecharge(_x, { account, business }) {
    //   await partnerBusinessService.subscribeViaResellerRecharge(
    //     account.id,
    //     business
    //   );
    // },
  },
};
