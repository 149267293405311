// export default ({ store }, inject) => {
//   inject("notifier", {
//     showMessage({ content = "", color = "" }) {
//       store.commit("snackbar/showMessage", { content, color });
//     },
//   });
// };

export default {
  install(Vue, { store }) {
    if (this.installed) return;
    this.installed = true;

    const notify = {
      showMessage({ content = "", color = "", timeout = 0 }) {
        store.commit("snackbar/showMessage", { content, color, timeout });
      },
    };

    Vue.prototype["$notifier"] = notify;
  },
};
