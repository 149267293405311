import axios from "axios";
import { getApiUrl } from "../../realms";

const getResellerBusinesses = async ({ resellerId, bId, options }) => {
  try {
    const response = await axios.get(
      `${getApiUrl()}/partner/lair/reseller/${resellerId}/businesses/`,
      {
        params: {
          bId,
          options
        }
      }
    );
    return response.data.businesses || [];
  } catch (e) {
    console.error(e);
    throw new Error(e.response.data || "server error");
  }
};

export default {
  getResellerBusinesses,
};
