/*

 * Represents the various realms (Zobaze, Ejmaly, Shopman) this project can operate on
 * Can have only one active realm against which actions can be run

*/

export default {
  namespaced: true,
  state: {
    realms: [
      {
        id: "zobaze",
        name: "Zobaze POS",
        enabledApps: ["pos", "resto", "bizli"],
        playBilling: true,
        stripeBilling: true,
        razorpayBilling: true,
      },
      {
        id: "ejmaly",
        name: "Ejmaly POS",
        enabledApps: ["pos"],
        playBilling: false,
        stripeBilling: false,
        razorpayBilling: false,
      },
      {
        id: "cjbem",
        name: "CJBEM POS"
      },
      {
        id: "shopman",
        name: "Shopman POS",
        enabledApps: ["pos"],
        playBilling: false,
        stripeBilling: false,
        razorpayBilling: false,
      }
    ],
    activeRealmId: "zobaze"
  },
  getters: {
    realms: (state) => state.realms,
    active: (state) => {
      let accounts = state.realms;
      let activeAccount = accounts[0];
      const selectedAccount = accounts.find((x) => x.id === state.activeRealmId);
      const account = selectedAccount || activeAccount;
      return account;
    }
  },
  mutations: {
    setActiveRealmId(state, realmId) {
      state.activeRealmId = realmId;
    },
  },
  actions: {
    init({ commit }) {
      const activeRealmId = localStorage.getItem("active_realm_id");
      if (activeRealmId) {
        commit("setActiveRealmId", activeRealmId);
      } else {
        commit("setActiveRealmId", "zobaze");
      }
    },
    setActiveRealmId({ commit }, realmId) {
      commit("setActiveRealmId", realmId);
      localStorage.setItem("active_realm_id", realmId)
    },
  },
};
