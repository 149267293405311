import axios from "axios";
import { getApiUrl } from "../realms";

const getUserSubscriptions = async (realmId, userId) => {
    const response = await axios.get(
        `${getApiUrl()}/realm/${realmId}/user/${userId}/subscriptions`
    );
    return response.data || [];
}

const getUserById = async (realmId, userId) => {
    const response = await axios.get(
        `${getApiUrl()}/realm/${realmId}/user/${userId}`
    );
    return response.data || [];
}

const getUserByEmail = async (realmId, email) => {
    const response = await axios.get(
        `${getApiUrl()}/realm/${realmId}/user?email=${email}`
    );
    return response.data || [];
}

const getUserByPhoneNumber = async (realmId, phone) => {
    const response = await axios.get(
        `${getApiUrl()}/realm/${realmId}/user?phone=${phone}`
    );
    return response.data || [];
}

export default {
    getUserSubscriptions,
    getUserById,
    getUserByEmail,
    getUserByPhoneNumber
}