import resellerBusinessService from "../../services/partner/reseller/business";
import whitelabelBusinessService from "../../services/partner/whitelabel/business";

export default {
  namespaced: true,
  state: {
    businessFetchError: null,
    businessList: [],
    businessFetchWaiting: true,
    businessCount: 0,
    loadedBusinessList: {}
  },
  getters: {
    businessList: (state) => state.businessList,
    businessFetchError: (state) => state.businessFetchError,
    businessFetchWaiting: (state) => state.businessFetchWaiting,
    businessCount: (state) => state.businessCount,
    loadedBusinessList: (state) => state.loadedBusinessList
  },
  mutations: {
    setBusinessList(state, businesses) {
      state.businessFetchWaiting = false;
      state.businessList = businesses;
    },
    setBusinessFetchError(state, message) {
      state.businessFetchWaiting = false;
      state.businessFetchError = message;
    },
    setLoadedBusinessList(state, { page, businesses }) {
      state.loadedBusinessList[page] = businesses;
    },
    clearAllBusinesses(state) {
      state.businessList = [];
      state.loadedBusinessList = {};
      state.businessFetchError = null;
      state.businessFetchWaiting = true;
      state.businessCount = 0;
    },
    setBusinessCount(state, total) {
      state.businessCount = total;
    }
  },
  actions: {
    async fetchBusinesses({ state, commit }, { activeAccount, bId, options }) {
      if(!options)
      {
        options = {
          sortDesc: false,
          sortBy: [],
          page: 1,
          itemsPerPage: 100,
          multiSort: false,
          mustSort: false,
          groupBy: [],
          groupDesc: [],
        };
      }
      state.businessFetchWaiting = true;
      try {
        if (!activeAccount) throw new Error("Bad State");
        let businesses = [];
        if (activeAccount.type == "whitelabel")
          businesses = await whitelabelBusinessService.getWhitelabelBusinesses(
            {
              wlId: activeAccount.id,
              bId,
              options
            }
          );
        else if (activeAccount.type == "reseller") {
          businesses = await resellerBusinessService.getResellerBusinesses(
            {
              resellerId: activeAccount.id,
              bId,
              options
            }
          );
        }
        commit("setBusinessList", businesses);
        commit("setBusinessCount", (businesses.length*options.page)+2);
        commit("setLoadedBusinessList", { page: options.page, businesses });
      } catch (error) {
        console.error(error);
        commit("setBusinessFetchError", error.message);
      }
    },
    async clearAllBusinesses({ commit }) {
      commit("clearAllBusinesses");
    },
    async setBusinessList({ state, commit }, businesses) {
      state.businessFetchWaiting = false;
      commit("setBusinessList", businesses);
    },
  },
};
