import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

const authRequired = (to, from, next) => {
  if (store.getters["user/isLoggedIn"]) {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: () => import("../pages/dashboard"),
  },
  {
    path: "/home",
    component: () => import("../pages/home"),
  },
  {
    path: "/login",
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isLoggedIn"]) {
        next("/master/home");
      } else {
        next();
      }
    },
    component: () => import("../pages/login"),
  },
  {
    path: "/partner",
    beforeEnter: authRequired,
    component: () => import("../pages/partner/index"),
    children: [
      {
        name: "select-account",
        path: "select-account",
        beforeEnter: authRequired,
        component: () => import("../pages/partner/select-account"),
      },
      {
        name: "partnerHome",
        path: "home",
        component: () => import("../pages/partner/home"),
      },
      {
        name: "partnerBusinesses",
        path: "businesses",
        component: () => import("../pages/partner/businesses"),
      },
      {
        name: "partnerViewBusiness",
        path: "business/:bId",
        component: () => import("../pages/partner/view-business"),
      },
    ],
  },
  {
    path: "/master",
    beforeEnter: authRequired,
    component: () => import("../pages/master/index"),
    children: [
      {
        name: "masterHome",
        path: "home",
        redirect: "/home",
      },
      {
        name: "masterCustomer",
        path: "customer",
        component: () => import("../pages/master/customer"),
      },
      {
        name: "masterPartner",
        path: "partner",
        component: () => import("../pages/master/partner"),
      },
      {
        name: "masterManageBusiness",
        path: "business/:bId",
        component: () => import("../pages/master/manage-business"),
      },
    ],
  },
  {
    path: "/golkonda/crm",
    component: () => import("../pages/golkonda/crm"),
  },
  {
    path: "/golkonda/mine",
    component: () => import("../pages/golkonda/mine"),
  },
  {
    name: "errorPage",
    path: "/error",
    component: () => import(/* webpackChunkName: "error" */ "../pages/error"),
    props: true,
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "error" */ "../pages/page404"),
  },
];

const router = new Router({
  linkActiveClass: "active",
  routes,
  mode: "history",
});

export default router;
